import { Root as MakeswiftRoot } from '@makeswift/runtime/components'
import { GridValue } from '@makeswift/runtime/dist/types/prop-controllers/descriptors'

type Video = {
	url?: string
}

type Props = {
	useTitleTemplate?: boolean
	children?: GridValue
	socialImageAlt?: string
	ogImageWidth?: number
	ogImageHeight?: number
	twitterImageWidth?: number
	twitterImageHeight?: number
	robots?: 'all' | 'noindex' | 'nofollow' | 'none'
	videos?: Video[]
}

export function Root({ children }: Props) {
	return <MakeswiftRoot>{children}</MakeswiftRoot>
}
